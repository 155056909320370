import OpenReplay from '@openreplay/tracker';
import trackerAxios from '@openreplay/tracker-axios';
import trackerAssist from '@openreplay/tracker-assist';

export default function startOpenReplay(name, email) {

    const onAgentConnect = () => {
        console.log("Live session started")
        const onAgentDisconnect = () => console.log("Live session stopped")
        return onAgentDisconnect
    }

    const tracker = new OpenReplay({
        projectKey: process.env.REACT_APP_PROJECT_KEY,
        ingestPoint: "https://openreplay.u3383.com/ingest",
        // onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
    });

    tracker.start();
    tracker.use(trackerAxios({ failuresOnly: false }));
    // tracker.use(trackerAssist({
    //     overrideConfig: { port: 4433 },
    //     onAgentConnect: onAgentConnect
    // }));
    tracker.setUserID(name + " (" + email + ")");
}
