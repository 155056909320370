import Echo from "laravel-echo";
import { useDispatch } from "react-redux";
import authHeader from '../helpers/auth-header'
import { updateNotification } from '@store/authentication';

export default function connectToChanel(uuid) {
    const dispatch = useDispatch()

    window.Pusher = require('pusher-js');
    window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.REACT_APP_WEBSOCKET_KEY,
        wsHost: process.env.REACT_APP_WEBSOCKET_HOST,
        wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
        forceTLS: false,
        disableStats: true,
        authEndpoint: process.env.REACT_APP_HOST + "/broadcasting/auth",
        auth: {
            headers: authHeader()
        }
    });

    window.Echo.private(`user.${uuid}`).notification((notification) => {
        dispatch(updateNotification(1));
      });
  
}
