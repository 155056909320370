import authHeader from "../helpers/auth-header";
import * as HELPER from "../helpers/general-helper";
import moment from 'moment';
const axios = require("axios")

export async function getMyProfile() {
  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/my-profile";

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        withCredentials: true,
        headers: authHeader(),
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function updateMyProfile(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/my-profile?_method=PUT",
        formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function changeMyPassword(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/my-profile/change-password?_method=PUT",
        formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function forgotMyPassword(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/forgot-password",
        formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getMyNotification(params) {
  let querystring = HELPER.paramBuilder(params);
  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/my-profile/notification" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        withCredentials: true,
        headers: authHeader(),
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

// user management
// declare an ajax request's cancelToken (globally)
let searchRejectRequest = null;
export async function getUserList(params) {
  // cancel previous ajax
  if (searchRejectRequest) {
    searchRejectRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  searchRejectRequest = axios.CancelToken.source();

  let querystring = HELPER.paramBuilder(params);

  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/admin/user" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        withCredentials: true,
        headers: authHeader(),
        cancelToken: searchRejectRequest.token
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function registerAdminUser(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/admin/user",
        formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getUserProfile(uuid, params) {
  let querystring = HELPER.paramBuilder(params);

  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/admin/user/" + uuid + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        withCredentials: true,
        headers: authHeader(),
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function updateUserProfile(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/admin/user?_method=PUT",
        formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function deleteUser(createData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data"
  header["Access-Control-Allow-Origin"] = "*"

  var formData = HELPER.appendFormData(createData);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_HOST + "/"
        + process.env.REACT_APP_API_PREFIX +
        "/admin/user?_method=DELETE", formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header
        })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getRoleList(params) {

  let querystring = HELPER.paramBuilder(params);

  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/admin/role" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        withCredentials: true,
        headers: authHeader(),
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getRolePermission(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/admin/role/" + id, {
        timeout: 60000,
        withCredentials: true,
        headers: authHeader(),
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function resendVerificationLink(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/email/resend",
        formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function resetPassword(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/reset-password",
        formData,
        {
          timeout: 60000,
          withCredentials: true,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

// import user
export async function downloadUserTemplate() {

  const today = new Date()
  let date = moment(today).format("DDMMYY");
  let filename = 'import_member_template-' + date

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/admin/download/member-excel-template"
    ;

  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
      withCredentials: true,
      headers: authHeader()
    })
      .then(response => {
        const { data } = response;
        resolve(data);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', filename + '.xlsx');
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function uploadUserTemplate(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data"
  header["Access-Control-Allow-Origin"] = "*"

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_HOST + "/"
        + process.env.REACT_APP_API_PREFIX +
        "/admin/user-import", formData,
        {
          timeout: 600000,
          withCredentials: true,
          headers: header
        })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}