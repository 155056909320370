import moment from "moment";
import { logout } from "../services/auth";
import toast from 'react-hot-toast';
import Cookies from "universal-cookie";
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useState, useEffect } from "react";

const cookies = new Cookies();

export default function cleanFormData(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj;
}

/* Append data into FormData instance */
export function appendFormData(data) {
  var formData = new FormData();
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return formData;
}

export function paramBuilder(rawParam) {
  let param = ""
  for (var key in rawParam) {
    if (rawParam[key]) {
      let symbol = param == '' ? '?' : '&';
      param = param + symbol + key + '=' + rawParam[key];
    }
  }
  return param;
}

export function checkUnauthorized(err, navigate) {
  if (err?.http_code == 401 || err?.http_code == 403) {
    for (const errKey in err.data?.errors) {
      if (errKey.split('.')[0] == 'token') {
        if (localStorage.getItem("admin_access_token")) {
          toast.error("Sorry, your login session has expired. Please login again.");
          logout();
          navigate("/login");
        }
        break;
      }
    }
    return true;
  } else {
    return false;
  }
}

export function validateDate(startdate, enddate) {
  let sdate = moment(startdate, "YYYY-MM-DD").startOf('day');
  let edate = moment(enddate, "YYYY-MM-DD").startOf('day');
  return sdate.isSameOrBefore(edate);
}

export function getNoImage() {
  // return "src/assets/images/img_no_image_276px.png";
  return "../assets/images/img_no_image_276px.png"
  // @src/assets/images/img_no_image_276px.png
}

export async function checkVisitorID() {
  if (window.OneSignal) {
    window.OneSignal.getUserId(function (userId) {
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 36);

      let settings = {
        path: "/",
        expires: expiryDate,
      };
      if (process.env.REACT_APP_DOMAIN) settings.domain = process.env.REACT_APP_DOMAIN;
      cookies.set("broadcastid", userId, settings);
    });
  }
}

export function generalErrorToast() {
  return toast.error("Oops, Something went wrong on server.")
}

export function checkDateValue(date) {
  if (date === "" || date === undefined || date === null) {
    return true
  } else {
    return false
  }
}

export function validatePhoneNo(phone, country) {
  // phone - text , country - country code
  return isValidPhoneNumber(phone, country)
}

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export function formatFileName(filename) {
  let finalname = "";
  let temp = filename.split("/");
  let nameOnly = temp[temp.length - 1];
  if (nameOnly) {
    finalname = nameOnly.split("?")[0];
  } else {
    finalname = nameOnly;
  }
  return finalname
}

export const displayContent = (permission, avaiModuleArray) => {
  let user_role = localStorage.getItem("user_role")
  if (user_role == 1) { // superadmin show all
    return true
  } else {
    if (avaiModuleArray?.includes(permission)) {
      return true
    } else return false
  }
}

export function convertThousandNumbers(num) {
  let amount = parseFloat(num).toFixed(2);
  let formatted = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formatted;
}

export const returnCurrencyUnit = (orderCurrency) => {
  switch (orderCurrency) {
    case "USD":
      return "US$";
    case "MYR":
      return "RM";
    default:
      return "RM";
  }
}

export const returnPriceBasedOnCurrency = (data, key, orderCurrency, conversionRate) => {
  let price = "";
  if (key == 'adjust_amount') {
    if ((data[key]).toString().charAt(0) == '-') {
      if (orderCurrency && orderCurrency == "USD") {
        price = parseFloat((data[key]).toString().substring(1)) / parseFloat(conversionRate)
      } else {
        price = parseFloat((data[key]).toString().substring(1)).toFixed(2)
      }
    } else {
      if (orderCurrency && orderCurrency == "USD") {
        price = parseFloat(data[key]).toFixed(2) / parseFloat(conversionRate)
      } else {
        price = parseFloat(data[key]).toFixed(2)
      }
    }
  } else {
    if (orderCurrency && orderCurrency == "USD") {
      price = parseFloat(data[key]) / parseFloat(conversionRate)
    } else {
      price = data[key]
    }
  }
  price = Math.round(price)
  return convertThousandNumbers(price)
}

export function addAttributeToVideo(type) {
  /**
   * youtube or vimeo use iframe
   * if type == preview, check if have video tag && dont have the controls attribute, add that attribute to video
   * else if type == edit, check if have video tag && have the controls attribute, remove attribute
   */
  let video = document.querySelector("video");
  if (type == "preview") {
      if (video && !video.hasAttribute("controls")) {
          video.setAttribute("controls", "true")
      }
  } else if (type == "edit") {
      if (video && video.hasAttribute("controls")) {
          video.removeAttribute("controls")
      }
  }
}
