import React, { useEffect } from "react";
import { getWithExpiry, setWithExpiry } from "../../helpers/general-helper";
import errorImg from '@src/assets/images/pages/error.svg'
import '@styles/base/pages/page-misc.scss'
import logo from "@src/assets/images/logo/img_syopex_logo.png"

export default function ErrorFallback({ error }) {
    // Handles failed lazy loading of a JS/CSS chunk.
    useEffect(() => {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error?.message && chunkFailedMessage.test(error.message)) {
            if (!getWithExpiry("chunk_failed")) {
                setWithExpiry("chunk_failed", "true", 10000);
                window.location.reload();
            }
        }
    }, [error]);

    return (
        <div className='misc-wrapper'>
            <a className='brand-logo' href='/'>
                <img src={logo} style={{ height: "2rem" }}></img>
            </a>
            <div className='misc-inner p-2 p-sm-3'>
                <div className='w-100 text-center'>
                    <h2 className='mb-1'>Something went wrong.</h2>
                    <p className='mb-2'>{error?.message}</p>
                    <img className='img-fluid' src={errorImg} alt='ErrorFallback' />
                </div>
            </div>
        </div>
    );
}
