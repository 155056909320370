// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import startOpenReplay from "../helpers/openReplay";
import connectToChanel from "../helpers/websocket";
import { getGeneralSystemSettings } from "../services/system";
import { getMyProfile } from "../services/user";

// const initialUser = () => {
//   const item = window.localStorage.getItem('userData');

//   //** Parse stored json or if none return initialValue
//   return item ? JSON.parse(item) : {}
// }

const initialState = {
  profile: null,
  system_setting: null,
  avai_module: [],
  permission: null,
  notification: 0,
}

export const checkAvailableModule = createAsyncThunk("/system-setting", async () => {
  let response;
  try {
    response = await getGeneralSystemSettings();
    return response.data?.system?.enable_modules;
  } catch (error) {
    showGeneralError(error);
  }
});

export const getUserProfile = createAsyncThunk("/my-profile", async (navigate) => {
  let response, result;
  try {
    response = await getMyProfile();
    result = await getGeneralSystemSettings();
    localStorage.setItem('user_role', response.data.roles[0].id);
    localStorage.setItem('hashed_uuid', response.data.one_signal_info?.hashed_uuid);
    if (response.data.dealer_info) { localStorage.setItem("is_dealer", JSON.stringify(response.data.dealer_info)) }

    // One signal
    if (response.data.uuid && response.data.one_signal_info?.hashed_uuid && window.OneSignal && result?.data?.system?.one_signal?.is_activate == 1) {
      window.OneSignal.setExternalUserId(response.data.uuid, response.data.one_signal_info?.hashed_uuid);
    }

    // open replay
    if (process.env.REACT_APP_HOST == process.env.REACT_APP_START_OPENREPLAY_URL) {
      startOpenReplay(response.data?.full_name, response.data?.email)
    }

    // websocket
    if (response.data?.uuid && process.env.REACT_APP_WEBSOCKET_KEY) { connectToChanel(response.data?.uuid) }

    return response.data;
  } catch (error) {
    checkUnauthorized(error, navigate);
  }
});

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    updateUserProfile: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    updateSystemSetting: (state, action) => {
      state.system_setting = { ...state.system_setting, ...action.payload };
    },
    updateAvailableModule: (state, action) => {
      state.avai_module = [...state.avai_module, ...action.payload];
    },
    updateUserPermission: (state, action) => {
      state.permission = { ...state.permission, ...action.payload };
    },
    handleLogin: (state, action) => {
      state.userData = action.payload
      localStorage.setItem('userData', JSON.stringify(action.payload))
    },
    handleLogout: state => {
      state.userData = {}
      localStorage.removeItem('userData')
    },
    updateFirstLoadNoti: (state, action) => {
      state.notification = action.payload;
    },
    updateNotification: (state, action) => {
      state.notification = state.notification + action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(checkAvailableModule.fulfilled, (state, action) => {
      state.avai_module = action.payload;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  }
})

export const { handleLogin, handleLogout, updateUserProfile, updateSystemSetting, updateAvailableModule, updateUserPermission, updateFirstLoadNoti, updateNotification } = authSlice.actions

export default authSlice.reducer
