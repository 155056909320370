import authHeader from "../helpers/auth-header";
import * as HELPER from "../helpers/general-helper";
const axios = require("axios")

export async function getSystemSetting(params) {

    let querystring = HELPER.paramBuilder(params);

    let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/admin/system-setting" + querystring;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                timeout: 60000,
                withCredentials:true,
                headers: authHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            })
            .finally();
    });
}

export async function updateSystemSetting(updateData) {
    let header = await authHeader();
    header["Content-Type"] = "multipart/form-data"
    header["Access-Control-Allow-Origin"] = "*"

    var formData = HELPER.appendFormData(updateData);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_HOST + "/"
                + process.env.REACT_APP_API_PREFIX +
                "/admin/system-setting?_method=PUT", formData,
                {
                    timeout: 60000,
                    withCredentials:true,
                    headers: header
                })
            .then(response => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            })
            .finally();
    });
}

export async function getLogisticSetting(params) {

    let querystring = HELPER.paramBuilder(params);

    let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/admin/system-setting/shipping-fee" + querystring;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                timeout: 60000,
                withCredentials:true,
                headers: authHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            })
            .finally();
    });
}

export async function updateLogisticSetting(updateData) {
    let header = await authHeader();
    header["Content-Type"] = "multipart/form-data"
    header["Access-Control-Allow-Origin"] = "*"

    var formData = HELPER.appendFormData(updateData);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_HOST + "/"
                + process.env.REACT_APP_API_PREFIX +
                "/admin/system-setting/shipping-fee?_method=PUT", formData,
                {
                    timeout: 60000,
                    withCredentials:true,
                    headers: header
                })
            .then(response => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            })
            .finally();
    });
}

export async function updateFreeShippingSetting(updateData) {
    let header = await authHeader();
    header["Content-Type"] = "multipart/form-data"
    header["Access-Control-Allow-Origin"] = "*"

    var formData = HELPER.appendFormData(updateData);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_HOST + "/"
                + process.env.REACT_APP_API_PREFIX +
                "/admin/system-setting/free-shipping?_method=PUT", formData,
                {
                    timeout: 60000,
                    withCredentials:true,
                    headers: header
                })
            .then(response => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            })
            .finally();
    });
}

export async function updateEnableModule(updateData) {
    let header = await authHeader();
    header["Content-Type"] = "multipart/form-data"
    header["Access-Control-Allow-Origin"] = "*"

    var formData = HELPER.appendFormData(updateData);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_HOST + "/"
                + process.env.REACT_APP_API_PREFIX +
                "/admin/system-setting/enable-module?_method=PUT", formData,
                {
                    timeout: 60000,
                    withCredentials:true,
                    headers: header
                })
            .then(response => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            })
            .finally();
    });
}

export async function getGeneralSystemSettings() {
    let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/system-setting";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    Accept: "application/json",
                    withCredentials: true,
                },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}
