import authHeader from "../helpers/auth-header";
const axios = require("axios")

function getExpireDate(expireInSeconds) {
    const now = new Date()
    const expireTime = new Date(now)
    expireTime.setSeconds(now.getSeconds() + expireInSeconds)
    return expireTime
}

export async function login(data) {
    let url = process.env.REACT_APP_HOST + '/' + process.env.REACT_APP_API_PREFIX + '/login'
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: {
                    Accept: "application/json"
                },
                // withCredentials: true
            })
            .then(response => {
                const { data } = response
                const token = data.data.access_token
                const expiryDate = getExpireDate(data.data.expires_in)
                const tokenData = {
                    access_token: token,
                    expiredAt: expiryDate
                }
                localStorage.setItem('admin_access_token', JSON.stringify(tokenData))
                resolve(token)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data
                        })
                    } else {
                        reject(error.response.data)
                    }
                } else {
                    reject(error.message)
                }
            })
            .finally()
    })
}

function logoutSuccess() {
    if (window.OneSignal) window.OneSignal.setExternalUserId("", localStorage.getItem("hashed_uuid"));
    localStorage.removeItem('admin_access_token')
    localStorage.removeItem('userData')
    localStorage.removeItem('print')
    localStorage.removeItem('admin_user_profile')
    localStorage.removeItem('enable_pickup')
    localStorage.removeItem('is_dealer')
    localStorage.removeItem('hashed_uuid')
    localStorage.removeItem('admin_state')
    localStorage.removeItem('user_role')
    localStorage.removeItem('availableModule')
}

export async function logout(history) {
    let header = await authHeader();
    header["Content-Type"] = "application/json"
    header["Access-Control-Allow-Origin"] = "*"
  
    let url = process.env.REACT_APP_HOST + '/' + process.env.REACT_APP_API_PREFIX + "/logout";

    return new Promise(async (resolve, reject) => {
        axios
            .post(url, null, {
                headers: header,
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                logoutSuccess();
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                logoutSuccess();
                if (error.response) {
                    if (error.response.data) {
                        reject(error.response.data);
                    }
                }
            });
    });
}